import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import IndexSectionFaqsGrayPattern5 from "../components/faqs-gray-pattern/IndexSectionFaqsGrayPattern5";
import IndexSectionFeaturesWhitePattern3 from "../components/features-white-pattern/IndexSectionFeaturesWhitePattern3";
import IndexSectionFootersGrayPattern7 from "../components/footers-gray-pattern/IndexSectionFootersGrayPattern7";
import IndexSectionHeadersWhitePattern2 from "../components/headers-white-pattern/IndexSectionHeadersWhitePattern2";
import LogoCloud from "../components/logo_cloud/logo_cloud";
import IndexSectionNumbersWhitePattern6 from "../components/numbers-white-pattern/IndexSectionNumbersWhitePattern6";
import ServicesSection from "../components/services/Services";
import IndexSectionTestimonialsWhitePattern4 from "../components/testimonials-white-pattern/IndexSectionTestimonialsWhitePattern4";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeadersWhitePattern2 />
      <LogoCloud />
      <IndexSectionFeaturesWhitePattern3 />
      <ServicesSection />
      <IndexSectionNumbersWhitePattern6 />
      <IndexSectionTestimonialsWhitePattern4 />
      <IndexSectionFaqsGrayPattern5 />
      <IndexSectionFootersGrayPattern7 />
    </React.Fragment>
  );
}
