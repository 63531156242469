import React from "react";

export default function IndexSectionNumbersWhitePattern6() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative py-20 bg-white overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className="position-absolute top-0 start-0 h-100 w-100"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
            }}
          />
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <div className="row g-16">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="text-center" data-aos="zoom-in">
                  <h2
                    className="mb-2 font-heading fs-5 text-success"
                    style={{ letterSpacing: "-1px" }}
                  >
                    235.000
                  </h2>
                  <p className="mb-0 fs-9 text-secondary fw-medium">
                    Cash Handled
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="text-center" data-aos="zoom-in">
                  <h2
                    className="mb-2 font-heading fs-5 text-success"
                    style={{ letterSpacing: "-1px" }}
                  >
                    $10m
                  </h2>
                  <p className="mb-0 fs-9 text-secondary fw-medium">
                    Shipments Delivered
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="text-center" data-aos="zoom-in">
                  <h2
                    className="mb-2 font-heading fs-5 text-success"
                    style={{ letterSpacing: "-1px" }}
                  >
                    +50.000
                  </h2>
                  <p className="mb-0 fs-9 text-secondary fw-medium">
                    Tons Moved
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="text-center" data-aos="zoom-in">
                  <h2
                    className="mb-2 font-heading fs-5 text-success"
                    style={{ letterSpacing: "-1px" }}
                  >
                    3.500
                  </h2>
                  <p className="mb-0 fs-9 text-secondary fw-medium">
                    Packages Fulfilled through Warehouse
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
