import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";

export default function SignInSectionSignInGrayPattern1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(login);
    if (login.email === "" || login.password === "") {
      alert("Please fill in all fields");
    }

    if (login.email === "jack@ukmail.com" && login.password === "admin") {
      dispatch(auth());
      navigate("/tracking_list");
    } else {
      alert("Incorrect email or password");
    }
  };

  return (
    <React.Fragment>
      <>
        <section
          className="position-relative pt-32 pb-24 bg-light-light overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className="position-absolute top-0 start-0 h-100 w-100"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
            }}
          />
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <div className="p-8 pt-14 pb-16 bg-white mw-xl mx-auto rounded-3 shadow-sm">
              <div className="mb-7 text-center mw-md mx-auto">
                <div
                  className="position-absolute top-0 start-50 translate-middle-x mt-n8 d-flex align-items-center justify-content-center mx-auto bg-success rounded-3"
                  style={{ width: 64, height: 64 }}
                >
                  <img
                    className="img-fluid"
                    style={{ height: 40 }}
                    src="flex-assets/images/sign-in/logo.svg"
                    alt=""
                  />
                </div>
                <h2 className="font-heading mb-4 fs-7">
                  Sign in to your account
                </h2>
              </div>
              <form className="mw-sm mx-auto" onSubmit={handleSubmit}>
                <div className="mb-4 row g-6">
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        className="mb-1 fw-medium text-light-dark"
                        htmlFor="signInInput11-1"
                      >
                        Email
                      </label>
                      <input
                        className="form-control text-secondary-dark shadow"
                        id="signInInput11-1"
                        name="email"
                        onChange={handleChange}
                        type="email"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label
                        className="mb-1 fw-medium text-light-dark"
                        htmlFor="signInInput11-2"
                      >
                        Password
                      </label>
                      <input
                        className="form-control text-secondary-dark shadow"
                        id="signInInput11-2"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        aria-describedby="passwordHelp"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-6 row align-items-center justify-content-between g-2">
                  <div className="col-auto">
                    <a
                      className="fs-13 fw-medium text-success link-success"
                      href="#"
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>
                <div className="mb-6 row">
                  <div className="col-12">
                    <button
                      className="btn btn-lg btn-success fs-11 py-3 w-100 text-success-light shadow"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
