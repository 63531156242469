import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import withAuth from "../auth/withAuth";
import Header from "../components/header/Header";
import { DB } from "../firebase/firebaseConfig";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

const AdminAddNewTracking = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("edit");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    consignmentNumber: "",
    bookingDate: "",
    serviceType: "",
    cnStatus: "",
    pieces: "",
    weight: "",
    receivedBy: "",
    originDestination: "",
    tracks: [{ time: "", location: "", status: "", details: "" }],
  });

  const handleChangeTrack = (event, index) => {
    const { name, value } = event.target;
    const updatedTracks = [...formData.tracks];
    updatedTracks[index][name] = value;
    setFormData({
      ...formData,
      tracks: updatedTracks,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddTrack = () => {
    setFormData({
      ...formData,
      tracks: [
        ...formData.tracks,
        { time: "", location: "", status: "", details: "" },
      ],
    });
  };

  const handleDeleteTrack = (index) => {
    const updatedTracks = formData.tracks.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      tracks: updatedTracks,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // Apply valiadtion to require all fields

    try {
      if (editId) {
        const updateRef = doc(DB, "trackings", editId);

        await updateDoc(updateRef, {
          ...formData,
          createdAt: new Date().toUTCString(),
        });
        toast("Updated Successfully ", {
          icon: "👏",
        });

        navigate(-1);
        return;
      }
      const docRef = await addDoc(collection(DB, "trackings"), {
        ...formData,
        createdAt: new Date().toUTCString(),
      });
      console.log("Document written with ID: ", docRef.id);
      toast("New Tracking Added ", {
        icon: "👏",
      });
      // Reset form data after submission (optional)

      setFormData({
        consignmentNumber: "",
        bookingDate: "",
        serviceType: "",
        cnStatus: "",
        pieces: "",
        weight: "",
        receivedBy: "",
        originDestination: "",
        tracks: [{ time: "", location: "", status: "", details: "" }],
      });
    } catch (e) {
      toast.error(e.message);

      console.log("Error adding document: ", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editId) {
      // Fetch data from firebase and set it to form data
      const docRef = doc(DB, "trackings", editId);
      // Fetch the document
      const fetchData = async () => {
        const docSnap = await getDoc(docRef);

        const data = { ...docSnap.data() };
        if (docSnap.exists()) {
          setFormData(data);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      };
      fetchData();
    }
  }, [editId]);

  return (
    <>
      <Header />
      <div className="container-fluid mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h2 className="mb-4 text-success">Create Package</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Consignment Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="consignmentNumber"
                  value={formData.consignmentNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Booking Date</label>
                <input
                  type="text"
                  className="form-control"
                  name="bookingDate"
                  value={formData.bookingDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Service Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="serviceType"
                  value={formData.serviceType}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Consignment Status</label>
                <input
                  type="text"
                  className="form-control"
                  name="cnStatus"
                  value={formData.cnStatus}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Pieces</label>
                <input
                  type="number"
                  className="form-control"
                  name="pieces"
                  value={formData.pieces}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Weight</label>
                <input
                  type="number"
                  className="form-control"
                  name="weight"
                  value={formData.weight}
                  required
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Received By</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  name="receivedBy"
                  value={formData.receivedBy}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Origin Destination</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="originDestination"
                  value={formData.originDestination}
                  onChange={handleChange}
                />
              </div>
              {/* Tracks */}
              <div className="form-group">
                <label>Tracks</label>
                {formData.tracks.map((track, index) => (
                  <div key={index}>
                    <div className="form-row">
                      <div className="col">
                        <input
                          type="datetime-local"
                          required
                          className="form-control my-2"
                          placeholder="Time"
                          name="time"
                          value={track.time}
                          onChange={(event) => handleChangeTrack(event, index)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          required
                          className="form-control my-2"
                          placeholder="Details"
                          name="details"
                          value={track.details}
                          onChange={(event) => handleChangeTrack(event, index)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          required
                          className="form-control my-2"
                          placeholder="Location"
                          name="location"
                          value={track.location}
                          onChange={(event) => handleChangeTrack(event, index)}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          required
                          className="form-control my-2"
                          placeholder="Status"
                          name="status"
                          value={track.status}
                          onChange={(event) => handleChangeTrack(event, index)}
                        />
                      </div>
                      {index === 0 ? null : (
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-danger my-2"
                            onClick={() => handleDeleteTrack(index)}
                          >
                            Delete
                          </button>
                        </div>
                      )}

                      <hr />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary my-2"
                  onClick={handleAddTrack}
                >
                  Add Track
                </button>
              </div>
              <button
                type="submit"
                className={`btn btn-success ${loading ? "disabled" : ""}`} // Disable button when loading
              >
                {loading && ( // Show spinner if loading is true
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {loading ? "Loading..." : editId ? "Update" : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(AdminAddNewTracking);
