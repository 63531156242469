import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SignInSectionSignInGrayPattern1 from "../components/sign-in-gray-pattern/SignInSectionSignInGrayPattern1";
import Header from "../components/header/Header";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SignIn() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Header />
      <SignInSectionSignInGrayPattern1 />
    </React.Fragment>
  );
}
