import React, { useState } from "react";

const ServicesSection = () => {
  return (
    <React.Fragment>
      <section
        className="position-relative py-20  px-10 bg-light-light overflow-hidden"
        style={{
          backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
        }}
      >
        {" "}
        <div
          className="container position-absolute top-0 start-0 "
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
          }}
        />
        <div className="container">
          <h2
            className="font-heading mb-4 fs-5"
            style={{ letterSpacing: "-1px" }}
          >
            Leaders in Delivery, Logistics and more..
          </h2>
          <div className="row">
            <MyCard3
              image="flex-assets/images/E-Commerce Logistics.jpg"
              title="E-Commerce Logistics"
              description="Our logistic solutions help you better manage your E-commerce business."
            />
            <MyCard3
              image="flex-assets/images/2149853128.jpg"
              title="International Logistics"
              description="With services in 20 locations worldwide, give your business our wings and get your products across the Globe.."
            />
            <MyCard3
              image="flex-assets/images/2149256092.png"
              title="Live Tracking & Support"
              description="Track your parcels on the go as they move across cities."
            />
            <MyCard3
              image="flex-assets/images/2148890018.jpg"
              title="Document Deliveries"
              description="Send your highly sensitive shipments such as SIMs, debit and credit cards"
            />

            <MyCard6
              image="flex-assets/images/1200.jpg"
              title="Global Freight Forwarding"
              description="Expand your reach with our global freight forwarding services. We offer air, sea and road freight services."
            />

            <MyCard6
              image="flex-assets/images/1day.png"
              title="1 day delivery"
              description="Get your packages delivered within 24 hours with our express delivery service."
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ServicesSection;
const MyCard3 = ({ image, title, description }) => {
  const [hovered, setHovered] = useState(false);

  const cardStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: hovered ? "scale(1.05)" : "scale(1)",
  };

  return (
    <div className="col-md-3">
      <div
        className="card mb-4 shadow-sm my-5 pb-5"
        style={cardStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img src={image} className="card-img-top" alt="Card Image" />
        <div
          className="card-body"
          style={{ height: "150px", overflow: "hidden" }}
        >
          <h5 className="font-weight-bold font-heading fs-8 mb-4">{title}</h5>
          <p
            className="card-text fw-medium text-secondary"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const MyCard6 = ({ image, title, description }) => {
  const [hovered, setHovered] = useState(false);

  const cardStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: hovered ? "scale(1.05)" : "scale(1)",
  };

  return (
    <div className="col-md-6">
      <div
        className="card mb-4 shadow-sm my-5"
        style={cardStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="row no-gutters">
          <div className="col-md-5">
            <img
              src={image}
              className="card-img"
              style={{
                width: "100%", // Ensure the image takes up the full width of the card
                height: "200px", // Set the desired height for the image
                objectFit: "cover", // Maintain aspect ratio and fill the entire space
              }}
              alt="Card Image"
            />
          </div>
          <div className="col-md-7">
            <div className="card-body">
              <h5 className="font-weight-bold font-heading fs-8 mb-4">
                {title}
              </h5>
              <p
                className="card-text fw-medium text-secondary"
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
