import React from "react";

const events = [
  {
    year: "1986",
    description: "Delivered 1st Packet on 23rd May 1986.",
    alignment: "left",
  },
  {
    year: "2001",
    description:
      "Expanded its operations in major cities and became one of the major players in the logistics industry.",
    alignment: "right",
  },
  {
    year: "2011",
    description:
      "With the growing market, OCS expanded its operations in 1200+ destinations and international markets as well.",
    alignment: "left",
  },
  {
    year: "2015",
    description:
      "With a vision to facilitate their customers even further and provide last-mile solutions to them; Muller and Phipps Accuried OCS Pakistan.",
    alignment: "right",
  },
  {
    year: "2022",
    description:
      "Overnite Express Express logistics is one of the top players in the Logistics industry in Pakistan. With 1600+ Nationwide and 200+ worldwide delivery locations with a fleet of 300+ owned /rented. Overnite Express Courier is trusted by 12000+ corporate clients and millions of Pakistanis.",
    alignment: "right",
  },
];

const renderEvent = (event, index) => {
  const eventClass = `timelinesec-${event.alignment} ${
    index === events.length - 1 ? "last" : ""
  }`;
  const yearClass = `col-md-3 col-sm-12 ${
    event.alignment === "right" ? "mobleDiv-push" : ""
  } wow flip animated`;
  const descriptionClass = `col-md-9 col-sm-12 ${
    event.alignment === "right" ? "mobleDiv-pull" : ""
  }`;

  return (
    <div className={eventClass} key={index}>
      <div className="row align-items-center">
        <div className={yearClass} data-wow-delay="0.4s">
          <div className="tlsec-hd">
            <span>{event.year}</span>
          </div>
        </div>

        <div className={descriptionClass}>
          <div className="mn-hd timeline-content">
            <p>{event.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Timeline = () => {
  return (
    <>
      <section
        className="position-relative py-20 bg-light-light overflow-hidden"
        style={{
          backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
        }}
      >
        <div
          className="position-absolute top-0 start-0 "
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
          }}
        />
        <div
          className="container wow lightSpeedIn animated mt-5"
          data-wow-delay="0.7s"
        >
          <div className="mb-24 text-center mw-md-5xl mx-auto">
            <h2
              data-aos="zoom-out"
              data-aos-duration="2000"
              className="font-heading mb-4 fs-5"
              style={{ letterSpacing: "-1px" }}
            >
              Overnite Express Timeline
            </h2>
          </div>
        </div>
        <section className="timelineSection">
          <div className="container">{events.map(renderEvent)}</div>
        </section>
      </section>
    </>
  );
};

export default Timeline;
