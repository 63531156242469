import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function withAuth(Component) {
  return function AuthenticatedComponent(props) {
    const { authenticated } = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
      if (!authenticated) {
        navigate("/"); // Redirect to the login page if not authenticated
      }
    }, [authenticated, history]);

    return <Component {...props} isAuthenticated={authenticated} />;
  };
}
