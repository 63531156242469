import React from "react";

export default function AboutUsSectionHowItWorksGrayPattern4() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative py-20 bg-light-light overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className="position-absolute top-0 start-0 h-100 w-100"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
            }}
          />
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <div className="row align-items-xl-center g-16">
              <div className="col-12 col-md-6">
                <div className="row justify-content-center justify-content-md-start">
                  <div className="col-auto">
                    <div className="position-relative" data-aos="fade-up-right">
                      <img
                        className="w-100"
                        src="flex-assets/images/how-it-works/ideas.png"
                        alt=""
                      />
                      <img
                        className="position-absolute top-0 end-0 mt-n8 me-n8"
                        src="flex-assets/elements/circle-orange.svg"
                        alt=""
                      />
                      <img
                        className="position-absolute bottom-0 start-0 mb-n8 ms-n10"
                        src="flex-assets/elements/dots-blue.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mw-md-xl">
                  <span
                    className="badge mb-4 bg-success-dark text-success text-uppercase shadow"
                    data-aos="fade-left"
                  >
                    How it works
                  </span>
                  <h2
                    className="font-heading mb-12 fs-5"
                    style={{ letterSpacing: "-1px" }}
                    data-aos="fade-left"
                  >
                    Our Commitment
                  </h2>
                  <div className="row g-8">
                    <div className="col-12 col-md-6" data-aos="flip-left">
                      <div
                        className="mb-4 d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success rounded-pill"
                        style={{ width: 48, height: 48 }}
                      >
                        1
                      </div>
                      <h3 className="mb-2 font-heading fs-9">Reliability</h3>
                      <p className="mb-0 text-secondary fw-medium">
                        {" "}
                        You can trust us to deliver your packages safely and on
                        time, every time.
                      </p>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left">
                      <div
                        className="mb-4 d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success rounded-pill"
                        style={{ width: 48, height: 48 }}
                      >
                        2
                      </div>
                      <h3 className="mb-2 font-heading fs-9">
                        Customer Satisfaction
                      </h3>
                      <p className="mb-0 text-secondary fw-medium">
                        Your satisfaction is our top priority. We go above and
                        beyond to ensure your delivery experience is hassle-free
                        and enjoyable.
                      </p>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left">
                      <div
                        className="mb-4 d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success rounded-pill"
                        style={{ width: 48, height: 48 }}
                      >
                        3
                      </div>
                      <h3 className="mb-2 font-heading fs-9">Innovation</h3>
                      <p className="mb-0 text-secondary fw-medium">
                        We continuously innovate to stay ahead of the curve,
                        leveraging technology and best practices to enhance our
                        services and improve efficiency.
                      </p>
                    </div>
                    <div className="col-12 col-md-6" data-aos="flip-left">
                      <div
                        className="mb-4 d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success rounded-pill"
                        style={{ width: 48, height: 48 }}
                      >
                        4
                      </div>
                      <h3 className="mb-2 font-heading fs-9">
                        Specialized Deliveries
                      </h3>
                      <p className="mb-0 text-secondary fw-medium">
                        {" "}
                        Have specialized delivery requirements? From perishable
                        goods to fragile items, we have the expertise and
                        resources to handle your unique shipment needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
