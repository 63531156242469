import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import _404SectionHttpCodesGrayPattern1 from "../components/http-codes-gray-pattern/_404SectionHttpCodesGrayPattern1";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import { DB } from "../firebase/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import toast from "react-hot-toast";
import moment from "moment";

const meta = {
  title: "Track Packages - Overnite Express",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Track() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const trackingNumberParam = searchParams.get("trackingNumber");
  console.log("🚀 ~ Track ~ trackingNumberParam:", trackingNumberParam);

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const handleTrackPackage = async () => {
    // query the database for the package details from firebase
    setLoading(true);
    try {
      const q = query(
        collection(DB, "trackings"),
        where("consignmentNumber", "==", trackingNumber || trackingNumberParam)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }

      // Since we're expecting only one document, we can use the first document in the query snapshot.
      const doc = querySnapshot.docs[0];
      const data = {
        id: doc.id,
        ...doc.data(),
      };
      setPackageDetails(data);
      console.log("Document:", data);
      return data;
    } catch (error) {
      toast.error("Error fetching document");
      console.error("Error fetching document: ", error);
      return null;
    } finally {
      setLoading(false);
    }

    const mockPackageDetails = {
      consignmentNumber: "556308010000006",
      bookingDate: "24-11-2023",
      serviceType: "Overnight",
      cnStatus: "DELIVERED",
      pieces: 1,
      weight: 0.8,
      receivedBy: "Dowran",
      originDestination: "PESHAWAR",
      tracks: [
        {
          time: "2024-04-20 10:00",
          location: "New York, NY",
          status: "Package picked up",
        },
        {
          time: "2024-04-21 15:00",
          location: "Los Angeles, CA",
          status: "In transit",
        },
        {
          time: "2024-04-22 09:00",
          location: "San Francisco, CA",
          status: "Out for delivery",
        },
        {
          time: "2024-04-22 12:00",
          location: "San Francisco, CA",
          status: "Delivered",
        },
      ],
    };
    setPackageDetails(mockPackageDetails);
  };

  useEffect(() => {
    if (trackingNumberParam) {
      setTrackingNumber(trackingNumberParam);
      handleTrackPackage();
    }
  }, []);
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      {/* HEADER */}
      <Header />
      {/* TRACK */}
      <div className="container mt-5">
        <div className="innerTracking-cont mn-hd">
          <h5>
            Track your &nbsp;
            <span>
              shipment<b>.</b>
            </span>
          </h5>
          <p>Track your order status with your consignment number.</p>
          <div id="tracking-input222" className="mainBanner-feild">
            <form id="trackformpage" action="" method="GET" className="d-flex">
              <input
                id="forminputvalue"
                type="text"
                required
                placeholder="Enter Tracking Number"
                autoComplete="off"
                className="form-control"
                value={trackingNumber}
                onChange={handleTrackingNumberChange}
              />
              <button
                id="formsubmitbtn"
                type="button"
                className="btn btn-success ml-2"
                onClick={handleTrackPackage}
                disabled={loading}
              >
                <i className="fas fa-shipping-fast"></i>
              </button>
            </form>
          </div>
        </div>
        {packageDetails && (
          <section className="innerTracking container">
            <div className="container-fluid">
              <div className="traking-table mn-hd">
                <div className="consignment">
                  <h5>
                    CONSIGNMENT NUMBER:{" "}
                    <span>{packageDetails.consignmentNumber}</span>
                  </h5>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="text-bold">Booking Date</td>
                        <td>{packageDetails.bookingDate}</td>
                      </tr>
                      <tr>
                        <td>Service Type</td>
                        <td>{packageDetails.serviceType}</td>
                      </tr>
                      <tr>
                        <td>CN Status</td>
                        <td>{packageDetails.cnStatus}</td>
                      </tr>
                      <tr>
                        <td>Pieces</td>
                        <td>{packageDetails.pieces}</td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>{packageDetails.weight}</td>
                      </tr>
                      <tr>
                        <td>Received By</td>
                        <td>{packageDetails.receivedBy}</td>
                      </tr>
                      <tr>
                        <td>Origin / Destination</td>
                        <td>{packageDetails.originDestination}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="shipment">
                  <h5>
                    Parcel{" "}
                    <span>
                      {" "}
                      Status<b>.</b>
                    </span>
                  </h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date time</th>
                        <th>Details</th>
                        <th>Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {packageDetails.tracks.map((track, index) => (
                        <tr key={index}>
                          <td>{moment(track?.time).format("LLL")}</td>
                          <td>{track.details}</td>
                          <td>{track.location}</td>
                          <td>{track.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p>
                  Thank you for using Overnite Express Courier service for your
                  shipment. This tracking result has been produced on the
                  current status of your shipment.
                </p>
              </div>
            </div>
          </section>
        )}
      </div>
    </React.Fragment>
  );
}

const ButtonSpinner = () => {
  return (
    <>
      <p>Loading...</p>
    </>
    // <div className="spinner-border" role="status">
    //   <span className="visually-hidden">Loading...</span>
    // </div>
  );
};
