import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = () => {
  const { user } = useSelector((state) => state);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const sidebarNavStyle = {
    transition: "transform 0.3s ease-in-out",
    transform: menuOpen ? "translateX(0)" : "translateX(-100%)",
  };
  return (
    <div>
      <section
        // className="position-relative py-24 bg-white overflow-hidden"
        style={{
          backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
        }}
      >
        <div className="position-relative">
          <nav className="navbar navbar-dark navbar-expand-lg py-5 py-lg-1">
            <div className="container-fluid">
              <a className="navbar-brand d-flex align-items-center" href="#">
                <img
                  style={{
                    width: "170px",
                  }}
                  className="img-fluid"
                  src="flex-assets/logos/1.svg"
                  alt=""
                />
                {/* <span
                  className="d-none d-md-block ms-2 text-success fw-bold fs-6"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  Overnite Express
                </span> */}
              </a>
              <div className="collapse navbar-collapse justify-content-center">
                <ul className="navbar-nav">
                  <li className="nav-item p-6">
                    <Link className="nav-link link-secondary p-0" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item p-6">
                    <Link className="nav-link link-secondary p-0" to="/aboutus">
                      About Us
                    </Link>
                  </li>

                  <li className="nav-item p-6">
                    <Link
                      className="nav-link link-secondary p-0"
                      to="/contact_us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  {user.authenticated && (
                    <>
                      <li className="nav-item p-6">
                        <Link
                          className="nav-link link-secondary p-0"
                          to="/tracking_list"
                        >
                          Tracking List
                        </Link>
                      </li>
                      <li className="nav-item p-6">
                        <Link
                          className="nav-link link-secondary p-0"
                          to="/add_new_tracking"
                        >
                          Add New Tracking
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className="d-none d-lg-block">
                <div className="row align-items-center g-6">
                  <div className="col-auto" />
                  <div className="col-auto">
                    <Link
                      className="btn btn-sm btn-success text-success-light shadow"
                      to="/track"
                    >
                      Track
                    </Link>
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <span className="btn navbar-burger p-0" onClick={toggleMenu}>
                  <svg
                    width={35}
                    height={35}
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      className="text-light-light"
                      width={32}
                      height={32}
                      rx={6}
                      fill="currentColor"
                    />
                    <path
                      className="text-secondary"
                      d="M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </nav>
          <div
            className={`navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs ${
              menuOpen ? "" : "d-none"
            }`}
            style={{ ...sidebarNavStyle, zIndex: 9999 }}
          >
            <div
              className="navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark"
              style={{ opacity: "75%" }}
            />
            <nav className="position-relative h-100 w-100 d-flex flex-column justify-content-between p-6 bg-white overflow-auto">
              <div className="d-flex align-items-center">
                <a className="me-auto h4 mb-0 text-decoration-none" href="#">
                  <img
                    className="img-fluid"
                    src="flex-assets/logos/1.svg"
                    alt=""
                  />
                </a>
                <a className="navbar-close text-secondary" onClick={toggleMenu}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-16">
                <ul className="navbar-nav">
                  <li className="nav-item p-6">
                    <Link className="nav-link link-secondary p-0" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item p-6">
                    <Link className="nav-link link-secondary p-0" to="/aboutus">
                      About Us
                    </Link>
                  </li>

                  <li className="nav-item p-6">
                    <Link
                      className="nav-link link-secondary p-0"
                      to="/contact_us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  {user.authenticated && (
                    <>
                      <li className="nav-item p-6">
                        <Link
                          className="nav-link link-secondary p-0"
                          to="/tracking_list"
                        >
                          Tracking List
                        </Link>
                      </li>
                      <li className="nav-item p-6">
                        <Link
                          className="nav-link link-secondary p-0"
                          to="/add_new_tracking"
                        >
                          Add New Tracking
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className="row align-items-center g-6">
                <div className="col-12">
                  <Link
                    className="btn btn-sm btn-success w-100 text-success-light shadow"
                    to="/track"
                  >
                    Track
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
