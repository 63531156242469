import React from 'react';

export default function AboutUsSection__structures2() {
    return (
        <React.Fragment>
            <>
                


            </>
        </React.Fragment>
    );
}

