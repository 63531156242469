import React from "react";

export default function AboutUsSectionHeadersWhitePattern3() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative bg-white overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div className="container">
            <div className="pt-28 pb-28 position-relative">
              <div className="row g-16">
                <div className="col-12 col-md-6" data-aos="fade-right">
                  <div className="mw-md-lg">
                    <h1
                      className="mb-6 font-heading fs-4"
                      style={{ letterSpacing: "-1px" }}
                    >
                      Welcome to{" "}
                      <span
                        className="text-success"
                        style={{ fontFamily: "Montserrat, sans-serif" }}
                      >
                        Overnite Express
                      </span>
                    </h1>
                    <p className="mb-8 fs-9 fw-medium text-secondary">
                      At Overnite Express, we are dedicated to providing
                      reliable, efficient, and secure courier delivery solutions
                      to meet the diverse needs of our clients.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6" data-aos="fade-left">
                  <div className="row justify-content-center">
                    <div className="position-relative col-auto">
                      <img
                        className="position-absolute top-0 start-0 mt-n12 ms-n12"
                        style={{ zIndex: 1 }}
                        src="flex-assets/elements/circle-orange.svg"
                        alt=""
                      />
                      <img
                        className="position-absolute bottom-0 end-0 me-n5 mb-n5"
                        style={{ zIndex: 1 }}
                        src="flex-assets/elements/dots-blue.svg"
                        alt=""
                      />
                      <img
                        style={{
                          borderRadius: "1rem",
                        }}
                        className="position-relative img-fluid "
                        src="flex-assets/images/about-hero.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
