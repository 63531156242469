import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "aos/dist/aos.css";

import _404Page from "./pages/404.js";
import AboutUsPage from "./pages/About_us.js";
import TermandConditions from "./pages/TermandConditions.jsx";
import AdminAddNewTrackingPage from "./pages/AdminAddNewTracking.js";
import AdminTrackingListPage from "./pages/AdminTrackingList.js";
import Contact_UsPage from "./pages/Contact_us.js";
import IndexPage from "./pages/Index.js";
import SignInPage from "./pages/Sign_in.js";
import TrackPage from "./pages/track.js";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/t&c" element={<TermandConditions />} />
      <Route path="/contact_us" element={<Contact_UsPage />} />
      <Route path="/track" element={<TrackPage />} />
      <Route path="/sign_in" element={<SignInPage />} />
      {/* Admin */}
      <Route path="/tracking_list" element={<AdminTrackingListPage />} />
      <Route path="/add_new_tracking" element={<AdminAddNewTrackingPage />} />
      <Route path="/*" element={<_404Page />} />
    </Routes>
  );
}

export default App;
