import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import withAuth from "../auth/withAuth";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { DB } from "../firebase/firebaseConfig";
import { Helmet, HelmetProvider } from "react-helmet-async";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const meta = {
  title: "Trackings - Overnite Express",
  meta: [],
  link: [],
  style: [],
  script: [],
};

const AdminTrackingList = () => {
  const navigate = useNavigate();

  const [mockTrackingData, setMockTrackingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const getAllDocs = async () => {
    const querySnapshot = await getDocs(collection(DB, "trackings"));
    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const sortedDocuments = documents.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setMockTrackingData(sortedDocuments);

    console.log("Documents:", documents);
  };

  useEffect(() => {
    getAllDocs();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  // Pagination
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = mockTrackingData.filter(
    (item) =>
      item.consignmentNumber
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.receivedBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.serviceType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.cnStatus.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCopy = (link) => {
    const cp = `https://overniteexpress.us/track?trackingNumber=${link}`;
    navigator.clipboard.writeText(cp);
    toast.success("Tracking Number Copied to Clipboard");
  };

  const handleEdit = (link) => {
    navigate(`/add_new_tracking/?edit=${link}`);
  };

  const handleDelete = (id) => {
    console.log("Delete id:", id);
    // Delete from firebase
    if (!window.confirm("Are you sure you want to delete this record?")) return;
    try {
      const docRef = doc(DB, "trackings", id);
      deleteDoc(docRef);
      toast.success("Tracking Record Deleted Successfully");
      getAllDocs();
    } catch (error) {
      console.error("Error deleting document: ", error);
      toast.error("Error Deleting Record");
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-4 text-success text-center">All Tracking Records</h2>
        <div className="mb-4">
          <input
            className="form-control"
            type="text"
            placeholder="Search by tracking number, receiver, service type, or status"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {currentItems.length === 0 ? (
          <CircularLoading />
        ) : (
          <>
            <table className="table table-auto table-striped table-hover">
              <thead>
                <tr>
                  <th>Creation</th>
                  <th>Tracking Number</th>
                  <th>Receiver</th>
                  <th>Service Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((trackingItem, i) => (
                  <tr key={trackingItem.id}>
                    <td>
                      <strong>
                        {moment(trackingItem?.createdAt).format("LL")}
                      </strong>
                    </td>
                    <td>{trackingItem.consignmentNumber}</td>
                    <td>{trackingItem.receivedBy}</td>
                    <td>{trackingItem.serviceType}</td>
                    <td>{trackingItem.cnStatus}</td>
                    <td>
                      <button
                        className="btn btn-success"
                        onClick={() =>
                          handleCopy(trackingItem.consignmentNumber)
                        }
                      >
                        Copy
                      </button>
                      <button
                        className="btn btn-warning mx-2"
                        onClick={() => handleEdit(trackingItem.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger mx-2"
                        onClick={() => handleDelete(trackingItem.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <nav>
                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(filteredData.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withAuth(AdminTrackingList);

const CircularLoading = () => {
  return (
    <div className="d-flex justify-content-center ">
      <div className="spinner-border text-sucess" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
