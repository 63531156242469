import React from "react";

export default function AboutUsSectionHowItWorksGrayPattern5() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative py-20 bg-light-light overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className="position-absolute top-0 start-0 h-100 w-100"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
            }}
          />
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <div
              className="mb-24 text-center mw-md-5xl mx-auto"
              data-aos="zoom-in"
            >
              <span className="badge mb-4 bg-success-dark text-success text-uppercase shadow">
                How it works
              </span>
              <h2
                className="font-heading mb-4 fs-5"
                style={{ letterSpacing: "-1px" }}
              >
                Why Choose Us
              </h2>
              <p className="mb-0 fs-9 fw-medium text-secondary">
                With our integrated CRM, project management, collaboration and
                invoicing capabilities, you can manage your business in one
                secure platform.
              </p>
            </div>
            <div className="mw-md-6xl mx-auto">
              <div className="row g-8">
                <div className="mb-8 col-12 col-md-4">
                  <div
                    className="position-relative p-8 pt-14 h-100 bg-white rounded shadow"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="position-absolute top-0 start-50 translate-middle-x mt-n7 border border-5 border-light-light overflow-hidden rounded-pill">
                      <div
                        className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success transition-transform"
                        style={{ width: 48, height: 48 }}
                      >
                        <i className="fas fa-shipping-fast"></i>{" "}
                        {/* Icon for fast delivery */}
                      </div>
                    </div>
                    <h3 className="mb-4 font-heading fs-9">Fast Delivery:</h3>
                    <p className="mb-0 fw-medium text-secondary">
                      Get your packages delivered swiftly and securely, ensuring
                      timely arrival at the destination.
                    </p>
                  </div>
                </div>
                <div className="mb-8 col-12 col-md-4">
                  <div
                    className="position-relative p-8 pt-14 h-100 bg-white rounded shadow"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="position-absolute top-0 start-50 translate-middle-x mt-n7 border border-5 border-light-light overflow-hidden rounded-pill">
                      <div
                        className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success transition-transform"
                        style={{ width: 48, height: 48 }}
                      >
                        <i className="fas fa-globe-americas"></i>{" "}
                        {/* Icon for international shipping */}
                      </div>
                    </div>
                    <h3 className="mb-4 font-heading fs-9">
                      International Shipping:
                    </h3>
                    <p className="mb-0 fw-medium text-secondary">
                      Seamlessly ship your packages across borders and
                      continents, expanding your reach globally.
                    </p>
                  </div>
                </div>
                <div className="mb-8 col-12 col-md-4">
                  <div
                    className="position-relative p-8 pt-14 h-100 bg-white rounded shadow"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="position-absolute top-0 start-50 translate-middle-x mt-n7 border border-5 border-light-light overflow-hidden rounded-pill">
                      <div
                        className="d-flex justify-content-center align-items-center fs-9 text-white fw-semibold bg-success transition-transform"
                        style={{ width: 48, height: 48 }}
                      >
                        <i className="fas fa-truck"></i>{" "}
                        {/* Icon for specialized deliveries */}
                      </div>
                    </div>
                    <h3 className="mb-4 font-heading fs-9">
                      Specialized Deliveries:
                    </h3>
                    <p className="mb-0 fw-medium text-secondary">
                      Trust us with your unique delivery needs, whether it's
                      fragile items or perishable goods.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
