import React from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";

export default function IndexSectionHeadersWhitePattern2() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative bg-white overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          {/* HEADER */}
          <Header data-aos="fade-down" />
          {/* HERO */}
          <div
            className="pt-12 pb-28 position-relative"
            style={{ zIndex: 1 }}
            data-aos="fade-up"
          >
            <div className="mb-16 text-center mw-md-3xl mx-auto">
              <span className="mb-6 badge bg-secondary text-white text-uppercase shadow">
                ABOUT
              </span>
              <h1
                className="mb-6 font-heading fs-4"
                style={{ letterSpacing: "-1px" }}
              >
                Delivering Excellence, Every Mile
              </h1>
              <p className="mb-8 fs-9 fw-medium text-secondary">
                Your Trusted Courier Partner for Swift, Secure Deliveries
              </p>
              <div className="row justify-content-center g-4">
                <div className="col-4 col-md-auto">
                  <Link
                    className="btn btn-lg btn-success w-100 text-success-light shadow"
                    to="/track"
                  >
                    Track
                  </Link>
                </div>
              </div>
            </div>
            <div className="row justify-content-center" data-aos="fade-right">
              <div className="position-relative col-auto">
                <img
                  className="position-absolute top-0 start-0 mt-n8 ms-n5"
                  style={{ zIndex: 1 }}
                  src="flex-assets/elements/wave-blue.svg"
                  alt=""
                />
                <img
                  className="position-absolute bottom-0 end-0 me-n6 mb-n8"
                  src="flex-assets/elements/wave-blue.svg"
                  alt=""
                />
                <div
                  className="position-relative overflow-hidden rounded-5"
                  style={{ height: 531 }}
                >
                  <img
                    className="h-100 w-100"
                    src="flex-assets/images/features/In no time-amico.svg"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
