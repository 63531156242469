import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AboutUsSectionContactsGrayPattern4 from "../components/contacts-gray-pattern/AboutUsSectionContactsGrayPattern4";
import Header from "../components/header/Header";
import IndexSectionFootersGrayPattern7 from "../components/footers-gray-pattern/IndexSectionFootersGrayPattern7";

const meta = {
  title: "Contact Us - Overnite Express",
  meta: [],
  link: [],
  style: [],
  script: [],
};
const Contact_Us = () => {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Header />
      <AboutUsSectionContactsGrayPattern4 />
      <IndexSectionFootersGrayPattern7 />
    </React.Fragment>
  );
};

export default Contact_Us;
