import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    authenticated: false,
    isAdmin: false,
    user: {},
  },
  reducers: {
    auth: (state, action) => {
      // this is for login
      state.authenticated = true;
      // state.user = action.payload;
      state.isAdmin = true;
    },
  },
});

// this is for dispatch
export const { auth } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
