import React from "react";
import { Link } from "react-router-dom";

export default function IndexSectionFootersGrayPattern7() {
  return (
    <React.Fragment>
      <>
        <section
          className="position-relative pt-20 bg-light-light overflow-hidden"
          style={{
            backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
          }}
        >
          <div
            className="position-absolute top-0 start-0 h-100 w-100"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)",
            }}
          />
          <div
            className="position-relative container pb-12"
            style={{ zIndex: 1 }}
          >
            <div className="row g-16">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="mw-md-xs">
                  <div className="d-flex mb-4">
                    <a href="#">
                      <img
                        className="img-fluid"
                        src="flex-assets/logos/1.svg"
                        style={{
                          width: "170px",
                        }}
                        alt=""
                      />
                    </a>
                  </div>
                  <p className="fs-10 fw-medium text-secondary">
                    OverNight Express delivers reliability and speed, ensuring
                    your packages arrive securely and on time. Trust us for all
                    your delivery needs.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="mw-md-xs">
                  <h3 className="font-heading mb-5 fs-10">Product</h3>
                  <ul className="nav flex-column">
                    <li className="nav-item mb-4">
                      <Link
                        className="nav-link p-0 fs-11 link-secondary"
                        to="/"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item mb-4">
                      <Link
                        className="nav-link p-0 fs-11 link-secondary"
                        to="/aboutus"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item mb-4">
                      <Link
                        className="nav-link p-0 fs-11 link-secondary"
                        to="/contact_us"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="nav-item mb-4">
                      <Link
                        className="nav-link p-0 fs-11 link-secondary"
                        to="/t&c"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="nav-item mb-4">
                      <Link
                        className="nav-link p-0 fs-11 link-secondary"
                        to="/privay-policy"
                      >
                        Privay Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-4">
                <div className="mw-md-sm">
                  <h3 className="font-heading mb-5 fs-10">Newsletter</h3>
                  <div className="row g-4 justify-content-center">
                    <div className="col">
                      <div className="form-group">
                        <input
                          className="form-control text-secondary-dark p-3 shadow"
                          id="footerInput9-1"
                          type="email"
                          aria-describedby="emailHelp"
                          defaultValue=""
                          placeholder="Enter email"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-auto">
                      <button
                        className="btn btn-lg btn-success fs-11 w-100 py-3 px-4 h-100 shadow"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative container" style={{ zIndex: 1 }}>
            <p className="mb-0 pt-10 pb-16 fs-12 fw-medium text-center text-secondary-light">
              © 2024 Overnite Express. All rights reserved.
            </p>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
