import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AboutUsSectionNavigationsWhite1 from "../components/navigations-white/AboutUsSectionNavigationsWhite1";
import AboutUsSection__structures2 from "../components/__structures/AboutUsSection__structures2";
import Header from "../components/header/Header";
import AboutUsSectionHeadersWhitePattern3 from "../components/headers-white-pattern/AboutUsSectionHeadersWhitePattern3";
import AboutUsSectionContactsGrayPattern4 from "../components/contacts-gray-pattern/AboutUsSectionContactsGrayPattern4";
import AboutUsSectionHowItWorksGrayPattern4 from "../components/how-it-works-gray-pattern/AboutUsSectionHowItWorksGrayPattern4";
import AboutUsSectionHowItWorksGrayPattern5 from "../components/how-it-works-gray-pattern/AboutUsSectionHowItWorksGrayPattern5";
import IndexSectionFootersGrayPattern7 from "../components/footers-gray-pattern/IndexSectionFootersGrayPattern7";
import Timeline from "../components/timeline/TimeLine";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function AboutUs() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <Header />
      <AboutUsSection__structures2 />
      <AboutUsSectionHeadersWhitePattern3 />
      <AboutUsSectionHowItWorksGrayPattern4 />
      <AboutUsSectionHowItWorksGrayPattern5 />
      <Timeline />
      <IndexSectionFootersGrayPattern7 />
    </React.Fragment>
  );
}
