import React from "react";

const LogoCloud = () => {
  return (
    <div>
      <section
        data-aos="fade-up"
        className="position-relative py-20 bg-light-light overflow-hidden"
        style={{
          backgroundImage: 'url("flex-assets/elements/pattern-light.png")',
        }}
      >
        <div
          className="position-absolute top-0 start-0 h-100 w-100"
          // style={{background: radialgradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%)}}
        ></div>
        <div className="position-relative container" style={{ zIndex: 1 }}>
          <h3 className="mb-8 font-heading fs-11 text-secondary-light fw-medium text-center">
            Working by the top countries in this industry
          </h3>
          <div className="row justify-content-center g-16">
            <div className="col-auto" data-aos="fade-up-right">
              <img
                style={{
                  width: "100px",
                }}
                className="img-fluid "
                src="flex-assets/images/flags/Flag_of_India_Flat_Round-512x512.png"
                alt=""
              />
            </div>
            <div className="col-auto" data-aos="fade-up-right">
              <img
                style={{
                  width: "100px",
                }}
                className="img-fluid "
                src="flex-assets/images/flags/Flag_of_Pakistan_Flat_Round-512x512.png"
                alt=""
              />
            </div>
            <div className="col-auto" data-aos="fade-up-right">
              <img
                style={{
                  width: "100px",
                }}
                className="img-fluid"
                src="flex-assets/images/flags/Flag_of_United_Arab_Emirates_Flat_Round-512x512.png"
                alt=""
              />
            </div>
            <div className="col-auto" data-aos="fade-up-right">
              <img
                style={{
                  width: "100px",
                }}
                className="img-fluid"
                src="flex-assets/images/flags/Flag_of_United_Kingdom_Flat_Round-512x512.png"
                alt=""
              />
            </div>
            <div className="col-auto" data-aos="fade-up-right">
              <img
                style={{
                  width: "100px",
                }}
                className="img-fluid"
                src="flex-assets/images/flags/Flag_of_United_States_Flat_Round-512x512.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LogoCloud;
